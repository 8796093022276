import { render, staticRenderFns } from "./PropertyDetail.vue?vue&type=template&id=bffd8bcc&scoped=true"
import script from "./PropertyDetail.vue?vue&type=script&lang=js"
export * from "./PropertyDetail.vue?vue&type=script&lang=js"
import style0 from "./PropertyDetail.vue?vue&type=style&index=0&id=bffd8bcc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_obqfykji6yask4lox7dm6zddym/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bffd8bcc",
  null
  
)

export default component.exports